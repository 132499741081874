import React from 'react';
import Layout from '../../components/layout';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Quote from '../../components/Quote/index';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}));

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const quote = () => {
  return (
    <>
      <Container fixed>
        <Paper style={{ minHeight: '600px', width: 'calc(100% + 24px)' }}>
          <Quote />
        </Paper>
      </Container>
    </>
  );
};

export default quote;
